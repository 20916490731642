/* App.css */
.form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #888;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
  }
  
  .form-container input {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn {
    margin: 5px;
  }
  
  /* Overlay to blur background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark background with opacity */
    z-index: 9;
  }
  