.main_gt{
    background-color: antiquewhite;
    border-radius: 10px;
    margin: 20px 0px;
    width:100%;

}
.gt{
    background-color: antiquewhite;
    border-radius: 10px;
    margin-top: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Snowburst+One&display=swap');
body{
margin:0;

}
#container1{
position: relative;
width:100%;

/* height:100vh; */
/* background-image: url(background.jpg);
background-size: cover;
background-position: center; */
overflow: hidden;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
/* h1{
color:#fff;
margin:0;
font-size: 100px;
font-family: 'Snowburst One', cursive;
} */
.snow{
position: absolute;
top:0;
left:0;
width:50px;
height:50px;
background-image: url('http://localhost:3000/assets/img/snow.png');
background-size: cover;
animation: animationSnow 4s ease-in-out infinite;
}
@keyframes animationSnow{
0%{
    transform: translate(0,0);
    opacity: 0;
}
50%{
    opacity: 1;
}
100%{
    opacity: 0;
    transform: translate(100px,50vh);
}
}