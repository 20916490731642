#menuButton:hover{
    color: rgb(248, 18, 221);
    cursor: pointer;
}
#menuContainer .list-group img{
    width: 30px;
    margin-right: 8px;
}
.list-group{
    margin: 32px 8px;
}
#menuContainer a{
    text-decoration: none;
}
#menuContainer a{
    color: black;
}
#menuContainer a:hover{
    color: rgb(244, 20, 232);
}
#closeButton {
    position: absolute;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}
.pro-name{
    color: rgb(85, 2, 9);
    text-decoration: none;
}

.pro-name:hover{
    color: rgb(41, 238, 113);
}