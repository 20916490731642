

.category img{
    width: 20px;
}
.category a{
    padding-left: 8px;
    text-decoration: none;
    color: black;
   
}
.category li a:hover{
    color: rgb(240, 61, 207);
    cursor: pointer;
}

/*  */

.sellings,.sellings2,.sellings3, #news{
    border-top: 3px solid rgb(240, 61, 207);
    position: relative;
}
.sellings >p,.sellings2 >p,.sellings3 >p , #news>p{
    color: black;
    font-weight: 500;
    position: absolute;
    left: 42%;
    top: -16px;
    font-size: 20px;
    background-color: white;
}
.sell,.sell2,.sell3{
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    transition: transform 0.5s ease-in-out;
}
.sel,.sel2,.sel3{
    overflow: hidden;
    width: 100%;
    margin: auto;
    /* border: 1px solid #ddd; */
}
.sell .sell-child,.sell2 .sell-child2,.sell3 .sell-child3{
    height: 420px;
    border: 1px solid #e8e6e6;
    min-width: 22.5%;
    margin: 0 17.5px;
    
}
.sell .sell-child a,.sell2 .sell-child2 a,.sell3 .sell-child3 a{
    display: flex;
    flex-direction: column;
    color: #000;
}
.sell .sell-child a:hover ,.sell2 .sell-child2 a:hover,.sell3 .sell-child3 a:hover{
    color: #fff;
    background-color: #34ba22;
}
.sell .sell-child a:hover p,.sell2 .sell-child2 a:hover p,.sell3 .sell-child3 a:hover p{
    color: #fff;
}

.run{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        /* background-color: #c54545; */
        border: none;
        cursor: pointer;
        font-size: 24px;
        padding: 10px;
        display: none;
        opacity: 0;
        transition: background-color 0.3s;
}
.run:hover {
    background-color: #ddd;
}
.sel:hover .run, .sel2:hover .run, .sel3:hover .run{
    display: block;
   
}

#prev , #prev2, #prev3 {
    left: -50px;
}

#next, #next2, #next3 {
    right: -50px;
}
.sel:hover #prev ,.sel2:hover #prev2, .sel3:hover #prev3 {
    animation: showArrowsLeft 1s forwards; /* Kích hoạt animation cho nút trái */
}

.sel:hover #next , .sel2:hover #next2, .sel3:hover #next3 {
    animation: showArrowsRight 1s forwards; /* Kích hoạt animation cho nút phải */
}

@keyframes showArrowsLeft {
    0% {
        opacity: 0;
        left: -50px;
    }
    100% {
        opacity: 1;
        left: 10px;
    }
}

@keyframes showArrowsRight {
    0% {
        opacity: 0;
        right: -50px;
    }
    100% {
        opacity: 1;
        right: 10px;
    }
}

@media (min-width: 1200px) {
    .h1, h1 {
        font-size: 1.1rem;
        text-align: center;
        padding: 4px;
    }
}

.price{
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: red;
}
.sell-child p{
    text-align: center;
    padding: 4px;
}
.banner{
    transition: transform 2s ease;
}

.banner:hover{
    transform: scale(0.95);
}
/* feed */
.daki a:hover{
    background-color: #64b457;
    color: #fff;
}
/* trượt */
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in.visible {
    opacity: 1;
    transform: translateY(0);
}
/* tip */
.tip{
    padding: 0 8px;
    height: 350px;
}
.tip a {
    text-decoration: none;
    color: #000;
}
.tip a:hover{
    color: #64b457;
}
@media (max-width: 768px) {
    .banner1 img{
        height: 150px;
    }
    .sellings{
        margin-top: 12px;
    }
    .sellings >p{
        color: black;
        font-weight: 450;
        position: absolute;
        left: 30%;
        top: -16px;
        font-size: 18px;
        background-color: white;
    }
    .sell .sell-child{
        min-width: 60%;
    }
}
@media (min-width: 768px) {
    .banner1 img{
        height: 370px;
    }
    .sellings{
        margin-top: 40px;
    }
    
}
