.sp_title{
    display:  flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c6c5c5;
    padding: 20px 0;
}
.sp_title button{
    color: #fff;
    font-size: .875rem;
    font-weight: 400;
    height: 40px;
    padding: 0 20px;
    background-color: #e5677d;
    border: none;
}
.address p{
    margin: 4px;
    color: #0000008A;
}
.address p .name{
    padding-right: 8px;
    margin-right: 8px;
    color: #000;
    border-right: 1px solid #6a6a6a;
}
.macd{
    padding: 4px;
    border: 1px solid red;
    display: inline-block;
}
.sp-bt_capnhat{
    border: none;
    color: blue;
    margin: 8px;
    background-color: #fff;
}
.sp-bt_md{
    border: 1px solid #6a6a6a;
    color: #6a6a6a;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark background with opacity */
    z-index: 9;
  }
  .form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #888;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
  }
  .form-popup .form-popup1 input{
    padding: 8px;
    border: 1px solid #c6c6c6;
    width: 220px;
    height: 40px;
  }
  .form-popup2 select{
    padding: 8px;
    border: 1px solid #c6c6c6;
    height: 40px;
    margin-top: 16px;
  }
  .form-popup3 input{
    padding: 8px;
    border: 1px solid #c6c6c6;
    height: 80px;
    margin-top: 12px;
    width: 100%;
  }
  .bt-them {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
  }
  @media (max-width: 768px) {
    .form-popup .form-popup1 input{
      width: 160px;
    height: 40px;
    }
  }